// eyecatcher
section.eyecatcher {
	position: relative;

	// &::after {
	// 	content: "";
	// 	background: url("/images/golf-white.png");

	// 	height: 9vh;
	// 	top: unset !important;
	// 	bottom: 0rem;
	// 	left: 0;
	// 	width: 100%;
	// 	background-size: 100% cover;
	// 	z-index: 1;
	// 	position: absolute;
	// 	transform: scaleX(-1);
	// }
	&::before {
		content: "";
		background: url("/images/bergen.svg");
		height: 9vh;
		bottom: 0;
		left: 0;
		width: 100%;
		background-size: 100% cover;
		z-index: 2;
		position: absolute;
		background-position: bottom;
		background-repeat: repeat-x;
	}
}
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;

	.owl-caption-holder {
		z-index: 2;
		height: 50%;
		display: flex;
		align-items: flex-end;
		.owl-title {
			display: none;
		}
		.owl-btn {
			display: none !important;
		}
	}
	.owl-title,
	.owl-subtitle,
	.owl-description,
	.owl-nav,
	.owl-dots {
		display: none;
	}
	&::after {
		content: "";
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background: linear-gradient((rgba(black, 0), rgba(black, 0.05) 60%));
		pointer-events: none;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: auto;
	min-height: 540px;
	.owl-title {
		font-size: $h1-font-size;
		display: block;
		@include media-breakpoint-down(md) {
			font-size: $h2-font-size;
		}
	}
	.owl-btn {
		display: inline-block !important;
		overflow: hidden;
		text-indent: -9999px;
		align-items: center;
		background: none;
		border: none;
		padding: 0 !important;
		box-shadow: none;

		&::after {
			content: "\f322";
			color: #fff;
			float: left;
			font-size: 30px;
			line-height: 28px;
			margin: 0;
			text-indent: 0;
			font-family: $font-awesome;
		}

		&:hover {
			transform: translateY(0.35rem);
			transition: 0.35s ease;
		}
	}
	@include media-breakpoint-up(sm) {
		height: 70vh;
	}
}

.omgeving-section.eyecatcher {
	max-height: unset !important;
	height: unset !important;
	z-index: 3;
	.owl-btn {
		display: none !important;
	}
	.owl-title {
		display: block !important;
		z-index: 1000 !important;
	}

	.owl-description {
		display: block !important;
	}
	.owl-caption-holder {
		height: 100% !important;
	}
	.owl-dots {
		display: block !important;
		z-index: 1000;
		position: absolute;
		bottom: 0;
		display: flex !important;
		justify-content: center;
		button {
			z-index: 1000;
		}
		&::after {
			content: "";
			background: url("/images/golf-white.png");
			z-index: -1;
			height: 9vh;
			top: unset !important;
			bottom: 0rem;
			left: 0;
			width: 100%;
			background-size: 100% cover;
			position: absolute;
		}
		button.owl-dot {
			span {
				border: 1px solid $green;
			}
			&.active {
				span {
					background: $green;
				}
			}
		}
	}
	&::after, &::before {
		display: none;
	}
}
