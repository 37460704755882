// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section + .content-section,
.lead-section + .bundle-overview-section {
	margin-top: calc(-16vh);
}

// home lead
.home-lead-section {
	padding: 6vh 0 39vh 0;
	min-height: unset !important;
	background: linear-gradient(to bottom, #c2daa0, #007552);
	position: relative;
	.container-one-column {
		.container-holder {
			max-width: 850px;
			margin: 0 auto;
			text-align: center;
			.column {
				* {
					color: $white;
				}
			}
		}
		.title {
			margin-bottom: 0.65rem;
			* {
				font-size: $h2-font-size !important;
				color: $white !important;
			}
		}
	}

	@include media-breakpoint-down(md) {
		.title * {
			font-size: $h3-font-size !important;
		}
	}
	&::after {
		content: "";
		background: url("/images/golf-white.png");
		height: 6vh;
		top: unset !important;
		bottom: 0rem;
		left: 0;
		width: 100%;
		background-size: 100% cover;
		position: absolute;
	}
}

// lead-section
// =========================================================================
.lead-section {
	padding: 6vh 0 18vh 0;
	min-height: 30vh;
	background: linear-gradient(to bottom, #c2daa0, #007552);
	position: relative;
	.container-one-column {
		.container-holder {
			margin: 0 auto;
			text-align: center;
			.column {
				color: $white;
				.title *,
				.page-title * {
					font-size: $h2-font-size;
					color: $white !important;
				}
				.subtitle * {
					color: $white !important;
				}
			}
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}
	}

	&::after {
		content: "";
		background: url("/images/golf-white.png");
		height: 6vh;
		top: unset !important;
		bottom: 0rem;
		left: 0;
		width: 100%;
		background-size: 100% cover;
		position: absolute;
	}
}

// services-section
.services-section {
	margin: 6vh 0;
	a {
		color: $blue;
	}
	.title {
		margin-bottom: 0.65rem;
	}
	h4 {
		color: $blue;
	}
}

// usp-section
.usp-section {
	margin: 6vh 0;
	.usp-grid-container {
		margin-top: -39vh;
		background: $white;
		border-radius: 20px;
		padding: 2.5rem;
		margin-bottom: 3vh;
		max-width: calc(1140px + 4rem);
		position: relative;
		.item {
			.card {
				border: none;
				border-radius: 6px;
				.card-image {
					position: relative;
					&::after {
						content: "";
						position: absolute;
						height: 100%;
						width: 100%;
						top: 0;
						left: 0;
						background: linear-gradient(
							(rgba(black, 0), rgba(black, 0.15) 90%)
						);
					}
				}
				.card-body {
					position: absolute;
					height: 100%;
					width: 100%;
					display: flex;
					.card-caption {
						display: flex;
						flex-flow: column;
						justify-content: flex-end;
						align-items: center;
						.card-title {
							color: $white !important;
						}
						.card-subtitle {
							color: $white !important;
							font-size: $h6-font-size !important;
							font-family: $font-family-primary;
						}
						.card-description {
							display: none;
						}
					}
				}
				&:hover {
					.card-title {
						transform: translateY(-0.5rem);
					}
				}
			}
		}
		@include media-breakpoint-down(md) {
			max-width: calc(100% - 30px);
			padding: 12px;
		}
	}
	.usp-info {
		.title {
			margin-bottom: 1rem;
		}
	}
	.usp-list {
		background: $green;
		padding: 2.5rem;
		border-radius: 20px;
		margin-top: 6vh;
		ul.vocabulary-term-list {
			display: flex;
			justify-content: space-between;
			gap: 1rem;
			li {
				display: flex;
				flex-flow: column;
				align-items: center;
				color: $white;
				max-width: 20%;
				i {
					font-size: 40px;
					color: $green;
					background: $white;
					aspect-ratio: 1/1;
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 1rem;
					border-radius: 50%;
					margin-bottom: 2rem;
					transition: 0.5s ease;
				}
				.list-item-title {
					font-size: $h4-font-size;
					font-family: $font-family-secondary;
				}
				.list-item-short-description {
					text-align: center;
					font-size: 16px;
					margin-top: 0.5rem;
				}
				&:hover {
					i {
						transform: scaleX(-1);
						transition: 0.5s ease;
					}
				}
			}
		}
		@include media-breakpoint-down(md) {
			max-width: calc(100% - 30px);
			padding: 30px;
			ul.vocabulary-term-list {
				flex-flow: row wrap;
				gap: 0.5rem;
				row-gap: 3rem;
				li {
					max-width: 47%;
					width: 47%;
					gap: 0rem;

					.list-item-title {
						text-align: center;
						font-size: 20px;
					}
					.list-item-short-description {
						display: none;
					}
					i {
						width: 70px;
						height: 70px;
						margin-bottom: unset !important;
					}
				}
			}
		}
	}
}

// content-section

&.contact {
	.content-section .container-holder {
		padding: 3rem 1.5rem;
	}
}

// =========================================================================
.content-section {
	margin: 0 auto;
	.container-holder {
		padding: 3rem 1.5rem;
		border-radius: 20px;
		background: $white;
		position: relative;
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 9 vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
	.container-holder {
		padding: 3rem 1.5rem;
		border-radius: 20px;
		background: $white;
		position: relative;
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: -12vh 0 6vh 0;
	.container-holder {
		padding: 3rem 1.5rem;
		border-radius: 20px;
		background: $white;
		position: relative;
	}
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-12vh);
	// margin-bottom: calc(-12vh + 30px);
	.container-holder {
		padding: 3rem 1.5rem;
		border-radius: 20px;
		background: $white;
		position: relative;
	}
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: -12vh 0 6vh 0;
	.container-holder {
		padding: 3rem 1.5rem;
		border-radius: 20px;
		background: $white;
	}
}
