// footer
.footer {
	&::before {
		content: "";
		background: url("/images/golf-green.png");
		height: 6vh;
		top: -6vh;
		left: 0;
		width: 100%;
		background-size: 100% cover;
		z-index: 2;
		position: absolute;
	}
	position: relative;
	margin-top: 12vh;
	background: linear-gradient(to bottom, #c2daa0, #007552);
	.container {
		.container-holder {
			.column {
				text-align: center;
			}
		}
	}
	.footer-logo {
		.container .container-holder {
			transform: translateY(-6vh);
			background: $white;
			padding-bottom: 9vh;
			border-radius: 0 0 20px 20px;
			position: relative;
			z-index: 999;
			&::after {
				content: url("/images/michael_boogert.svg");
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translate(-50%, 50%);
			}
			@include media-breakpoint-down(md) {
				max-width: calc(100% - 2.5px);
				margin: auto;
			}
		}
	}
	.footer-contact {
		padding: 6vh 0;
		.footer-text h6,
		h5,
		h4,
		h3,
		h2,
		h1 {
			font-family: $font-family-primary !important;
		}
		.contact-links {
			a {
				font-weight: 700;
				color: $white;
			}
		}
		ul.vocabulary-term-list {
			display: flex;
			gap: 1rem;
			justify-content: center;
			margin-top: 1.5rem;
			li {
				a {
					text-decoration: none;
					.list-item-title {
						display: none;
					}
					i {
						font-size: 24px;
						color: $black;
						background: $white;
						aspect-ratio: 1/1 !important;
						width: 60px;
						display: flex;
						justify-content: center;
						align-items: center;
						padding: 1rem;
						border-radius: 50%;
					}
					&:hover {
						i {
							transform: rotate(360deg);
							transition: 0.5s ease;
						}
					}
				}
			}
		}
	}
	.footer-socket {
		position: relative;
		padding: 0.5rem 0;
		.list {
			width: 100%;
			display: flex;
			justify-content: center;
			gap: 1.5rem;
			li a {
				color: #c2daa0;
				font-size: 15px;
				text-decoration: none;
			}
			@include media-breakpoint-down(md) {
				flex-flow: column;
				gap: 0.25rem;
			}
		}
		.partner-logo {
			position: absolute;
			right: 0;
			bottom: 0;
			@include media-breakpoint-down(md) {
				position: relative;
				order: -1;
				margin-bottom: 1rem;
			}
		}
	}
}
