// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	transition: 0.5s;

	&.sticky {
		box-shadow: $shadow;
		.logo {
			&.illustration-logo {
				width: 75px !important;
				transition: 0.35s ease;
			}
			&.text-logo {
				width: 150px;
				transition: 0.35s ease;
			}
		}
	}

	.container,
	.container-fluid {
		.container-holder {
			> .column {
				align-items: center;
				justify-content: center;
				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
		&.top {
			background: $blue !important;
			padding: 0.5rem 15px;
			* {
				z-index: 2;
			}
		}
		&.bottom {
			padding: 0.5rem 15px;
			overflow-y: visible;
			max-height: 120px;
			.column {
				display: flex;
				align-items: flex-start;
				gap: 1.5rem;
			}
			@include media-breakpoint-down(md) {
				max-height: 90px;
				.column {
					display: flex;
					flex-flow: row nowrap !important;
					align-items: flex-start;
				}
				&.shrink {
					.logo {
						&.illustration-logo {
							width: 70px;
							margin-left: 15px;
						}
						&.text-logo {
							height: 70px;
						}
					}
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		margin-right: auto;
		i {
			color: $white;
			transition: 0.5s ease-in;
		}
		.navbar-toggler[aria-expanded="true"] {
			i {
				transform: rotate(360deg);
				transition: 0.5s ease-in;
				&::before {
					content: "\f00d";
				}
			}
		}
	}

	// logo
	.logo {
		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
		&.text-logo {
			width: 311px;
			margin-top: 1rem;
			transition: 0.35s ease;
		}
		&.illustration-logo {
			width: 195px;
			position: relative;

			&::after {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-52%, -50%);
				width: 125%;

				aspect-ratio: 1/1;
				border-radius: 50%;
				background: $white;
				z-index: -1;
			}
			transition: 0.35s ease;
		}
		@include media-breakpoint-down(md) {
			&.text-logo {
				width: 210px;
			}
			&.illustration-logo {
				width: 128px;
				margin-right: auto;
			}
		}
	}
	// header btn
	div.header-btn {
		a {
			@extend .btn, .btn-outline-secondary;
			border-color: $white;
			color: $white;
			width: 111px;
			height: 36px;
			font-size: 16px;
			padding: 0.15rem 0.5rem !important;
			display: flex;
			align-items: center;
			justify-content: center;
			width: max-content;
			&:hover {
				background: $white;
				color: $blue;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(md) {
			display: none;
			&.mobile-menu {
				left: -100vw;
				position: absolute;
				height: 100vh;
				display: unset !important;
				order: 2;
				padding: 1.5rem 30px !important;
				background: $white;
				width: 100%;
				transition: 0.35s ease;
				.navbar-nav {
					.nav-item {
						.nav-link {
							font-weight: 700;
							font-family: $font-family-primary;
							text-transform: uppercase;
							display: flex;
							align-items: center;
							width: 100%;
							justify-content: space-between;
						}
						.dropdown-menu.show {
							position: static !important;
							border: none !important;
							transform: none !important;
							padding: 0 !important;
							.nav-link {
								font-weight: 200;
								padding-left: 1rem;
							}
						}
					}
				}
			}
			&.mobile-menu.show {
				left: 0;
				transition: 0.35s ease;
			}
		}

		@include media-breakpoint-up(lg) {
			&.mobile-menu {
				display: none !important;
				position: absolute;
				visibility: hidden;
			}
			margin: auto;
			.navbar-nav {
				gap: 1rem;
				margin: auto;
				left: 0;
				right: 0;
				display: flex;
				justify-content: center;
				position: absolute;
				width: 90%;
				z-index: 1;
				.nav-item {
					.nav-link {
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						color: $white;
					}
					&.active,
					&:hover {
						.nav-link {
							color: $blue-dark;
						}
					}
				}
			}
			.dropdown-menu.show {
				border: none;
				background: $blue;
				box-shadow: none;
				padding-left: 1rem;
				border-radius: 0 0 20px 20px;
				.nav-link {
					color: $white !important;
				}
				.nav-item.active,
				.nav-item:hover {
					.nav-link {
						color: $blue-dark !important;
					}
				}
				&::after,
				&::before {
					display: none !important;
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: 1rem;
		&.desktop {
			@include media-breakpoint-down(md) {
				display: none;
			}
		}
		&.mobile {
			display: none;
			@include media-breakpoint-down(md) {
				display: block;
				a {
					border: none !important;
					box-shadow: none !important;
				}
				.dropdown-menu-right.show {
					background: $blue !important;
					border: none !important;
				}
			}
			.dropdown-toggle {
				display: flex !important;
				align-items: center;

				&::after {
					color: $white !important;
				}
			}
		}
	}
}
