// mini-sab
.mini-sab {
	position: relative;
	background: linear-gradient(to bottom, #c2daa0, #c2daa0);
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 100;
			position: relative;
			margin: 15px 0 0 0;
			padding: 15px 0;
			border-radius: 4px;
			background: none;
			color: #fff;

			// below eyecatcher
			@include media-breakpoint-up(lg) {
				margin-top: 50px;
				padding-left: 30px;
				padding-right: 30px;
			}

			// above eyecatcher
			/* @include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 0;
			} */
		}
	}

}

&.accommodation-category-overview,
&.accommodation-category-detail,
&.accommodation-detail {
	.mini-sab {
		height: 0 !important;
	}
}
